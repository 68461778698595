import React from 'react'
import * as s from "../Styles";

function Footer() {
  return (
    <s.HeaderWrapper>
    <s.Text style={{paddingLeft: 10}}>
    MYYC 2022 - Tribute © 2022
    </s.Text>

    <s.IconsWrapper>
    <a href='https://opensea.io/' rel='noreferrer' target={'_blank'} >
    <s.Icons src='/images/os.svg' style={{filter: 'invert(1)'}}  alt='logo'/>
    </a>
    <a href='https://twitter.com/MY00tsYC' rel='noreferrer' target={'_blank'}>
    <s.Icons src='/images/twt.png'  alt='logo'/>
    </a>
    </s.IconsWrapper>
    </s.HeaderWrapper>
  )
}

export default Footer